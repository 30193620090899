import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { AppContext } from "../../../../context/AppContext";
import SubHeader from "../../../../sharedComponent/SubHeader";
import { Nav, Tab } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import CommonDatePicker from "../../../../sharedComponent/CommonDatePicker";
import Form from 'react-bootstrap/Form';
import MapComponent from "../../../../sharedComponent/MapComponent";
import ApiConfig from "../../../../api/ApiConfig";
import { getWithAuthCall, simplePostCall } from "../../../../api/ApiServices";
import { latestDate } from "../../../../sharedComponent/common";
import NoDataComp from "../../../../sharedComponent/NoDataComp";
import { MapContainer, TileLayer, Marker, Circle, Rectangle, Polygon } from 'react-leaflet';
import Loader from "../../../../sharedComponent/Loader";
import L from 'leaflet';
import vehicle7 from "../../../../assets/images/yellow-card-map.svg";
import { useMap } from 'react-leaflet';
import step2 from "../../../../assets/images/step2.svg";
import { Select } from "antd";
const { Option } = Select;


const VehicleparkingSlot = () => {
  const { t } = useTranslation();
  const [tripList, setTripList] = useState([]);
  const { sidebar, setLinkReport, LinkReport, setOptionDynamicDownload, OptionDynamicDownload } =
    useContext(AppContext);
  const [currentDate, setCurrentDate] = useState({ toDayDate: new Date() });
  const maxDate = new Date();
  const [showMap, setShowMap] = useState(false);
  const [mapDataLive, setMapDataLive] = useState([]);
  const [shareLink, setShareLink] = useState(false);
  const [shareLinkData, setShareLinkData] = useState("10");
  const [selectedPopmMrker, setSelectedPopMarker] = useState(0);
  const [customerSettingdata, setCustomerSettingdata] = useState({});
  const [vehicleList, setvehicleList] = useState([]);
  const [ParkingList, setParkingList] = useState([]);
  const [VehicleId, setVehicleId] = useState("");
  const [ParkingId, setParkingId] = useState("");
  const [FilterId, seFilterId] = useState("Still Parked");
  const [currentTab, setCurrentTab] = useState("slot");

  const [OrderListData, setOrderListData] = useState([]);
  const [DrowData, setDrowData] = useState([]);
  console.log("DrowData", DrowData);
  const [pdfData, setpdfData] = useState("");
  const [loading, setLoading] = useState(false);

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  useEffect(() => {

    reportViewList();
  }, [LinkReport, VehicleId, FilterId, currentDate, ParkingId, currentTab]);

  useEffect(() => {
    if (OptionDynamicDownload === "pdf" || OptionDynamicDownload === "excel") {
      reportViewList();
    }
  }, [OptionDynamicDownload]);

  const downloadFile = () => {
    fetch(pdfData)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const a = document.createElement("a");
        a.href = url;

        // Extract the file name from the filepath dynamically
        const fileName = pdfData.substring(pdfData.lastIndexOf("/") + 1);
        a.download = fileName;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("Error downloading file:", error))
        .finally(() => {
        // Clear state or perform cleanup here
        setpdfData("") 
    });
  };
  useEffect(() => {
    if (pdfData) {
      downloadFile();
    }
  }, [pdfData]);


  const center = DrowData?.length > 0
    ? [parseFloat(DrowData[0].slot_gps_latitude), parseFloat(DrowData[0].slot_gps_longitude)]
    : [25.276987, 51.520008]; // Default coordinates if no data



  const MoveToLocation = ({ center }) => {
    const map = useMap();

    useEffect(() => {
      if (center) {
        map.setView(center, map.getZoom()); // Move the map to the new center
      }
    }, [center, map]);

    return null;
  };




  const getMarkerIcon = (strength) => {
    // Define custom icon for vehicles
    return L.icon({
      iconUrl: vehicle7,
      iconSize: [24, 24], // Adjust size based on your icon's dimensions
      iconAnchor: [12, 24] // Adjust anchor point for your icon
    });
  };


  const reportViewList = () => {
    let requestData;

    requestData = {

      format: OptionDynamicDownload,
      vehicle_id: VehicleId,
      filterby: FilterId,
      startdate: latestDate(currentDate.toDayDate, "yyyy-MM-dd"),
      parking_slot: ParkingId,
      type: currentTab,
      //  startdate: "2024-10-17",
      // enddate:"2024-10-17"



    };

    if (requestData) {
      setLoading(true);
      simplePostCall(
        ApiConfig.GET_REPORT_LSIT + LinkReport,
        JSON.stringify(requestData)
      )
        .then((res) => {
          if (res.result) {
            setOptionDynamicDownload("")
            setLoading(false);

            // Extract the array of items from the response
            const firstKey = res.filepath ? res.filepath : res?.filePath;
            setpdfData(firstKey);
            if (!firstKey) {
              setOrderListData(res.listData);
              setDrowData(res.slotlocation);
            }
            // Set the array to the state

          } else {
            setOrderListData([]);
            setDrowData([]);


            // Handle the case when the result is false
            // notifyError(res.message);
          }
        })
        .catch((err) => {
          console.log("Error:", err);
        })
        .finally(() => {
          // setLoading(false);
        });
    }
  };

  useEffect(() => {
    getVehicelList();
    getParkingList()
  }, [,]);

  function getVehicelList() {
    getWithAuthCall(ApiConfig.VEHICEL_AVALIVALITY_DROPDAWAN)
      .then((data) => {
        console.log(data);
        // setVehicalType(data?.data);
        setvehicleList(data?.data);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }



  function getParkingList() {
    getWithAuthCall(ApiConfig.PARKING_AVALIVALITY_DROPDAWAN)
      .then((data) => {
        // setVehicalType(data?.data);
        setParkingList( data.data ? data?.data : data.listData);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  return (
    <>
      <motion.div
        className={sidebar && sidebar ? "taskMain " : "cx-active  taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        <div id="cx-wrapper" className="Vehicle_inspition_dashboard heightFixed">
          <div className="row">
            <div className="col-md-12 mb-4">
              <SubHeader  />
            </div>
            <div className="col-md-12">
              <div className="parkingBox">
                <Tab.Container
                  id="left-tabs-example"
                  className="td-tab-wrapper"
                  defaultActiveKey="slot"
                >
                  <Nav variant="pills"
                    onSelect={(selectedKey) => setCurrentTab(`${selectedKey}`)}

                  >
                    <Nav.Item>
                      <Nav.Link href="#" eventKey="slot"
                        onChange={(e) => setCurrentDate({ toDayDate: new Date() })}
                      >Vehicles By Parking Slot</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        onChange={(e) => setCurrentDate({ toDayDate: new Date() })}
                        eventKey="checkin">Vehicles Checkin/Checkout</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="slot">
                      <div className="parkingTabBox">
                        <div className="row">
                          <div className="col-md-3 mb-3">
                            <div className="dropDowns">

                              {/* <select
                                className="form-select"
                                aria-label="Default select example"
                                placeholder=" Slot Namet"
                                onChange={(e) => setParkingId(e.target.value)}
                              >
                                <option value={0}>Slot Namet</option>
                                {ParkingList?.map((vehicle) => (
                                  <option Value={vehicle.slot_id}>
                                    {vehicle.slot_name}
                                  </option>
                                ))}
                              </select> */}
                              <Select
                  style={{ height: "40px", width: "100%" }}
                  className="custom-select"
                  placeholder={t("Slot name")}
                  onChange={(value) => setParkingId(value)}
                  onChange={(value) => setParkingId(value)}
                  showSearch // Enables search functionality
                  filterOption={(input, option) =>
                    option?.label
                      ?.toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  optionFilterProp="label"
    filterSort={(optionA, optionB) =>
      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
    }
                >
                  <Option value="" style={{ color: "rgba(156, 73, 0)" }}>
                    {t("Vehicle")}
                  </Option>
                  { ParkingList && ParkingList?.length > 0 && ParkingList?.map((vehicle) => (
                    <Option
                    key={vehicle.slot_id}
                      value={vehicle.slot_id}
                      style={{ color: "rgba(156, 73, 0)" }}
                      label= {vehicle.slot_name}
                    >
                      {vehicle.slot_name}
                    </Option>
                  ))}
                </Select>
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className="dropDowns">
                              {/* <select
                                className="form-select"
                                aria-label="Default select example"
                                placeholder="Vehicle list"
                                onChange={(e) => setVehicleId(e.target.value)}
                              >
                                <option value={0}>Vehicle</option>
                                {vehicleList.map((vehicle) => (
                                  <option Value={vehicle.vehicle_id}>
                                    {vehicle.vehicle_number}
                                  </option>
                                ))}

                              </select> */}
                              <Select
                  style={{ height: "40px", width: "100%" }}
                  className="custom-select"
                  placeholder={t("Vehicle list")}
                  onChange={(value) => setVehicleId(value)}
                  onChange={(value) => setVehicleId(value)}
                  showSearch // Enables search functionality
                  filterOption={(input, option) =>
                    option?.label
                      ?.toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  optionFilterProp="label"
    filterSort={(optionA, optionB) =>
      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
    }
                >
                  <Option value="" style={{ color: "rgba(156, 73, 0)" }}>
                    {t("Vehicle")}
                  </Option>
                  {vehicleList && vehicleList?.length > 0 && vehicleList?.map((vehicle) => (
                    <Option
                    key={vehicle.vehicle_id}
                      value={vehicle.vehicle_id}
                      style={{ color: "rgba(156, 73, 0)" }}
                      label= {vehicle.vehicle_number}
                    >
                      {vehicle.vehicle_number}
                    </Option>
                  ))}
                </Select>
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className="innerSelectBox weekCounter datepicker-main BGiMP mr-3">
                              <CommonDatePicker
                                dateKey={"toDayDate"}
                                setDate={setCurrentDate}
                                data={currentDate}
                                SetPlaceholder={"Today Date"}
                                dataDisbal={maxDate}
                              />
                            </div>
                          </div>
                          {/* <div className="col-md-3 mb-3">
                                                        <button className="submitBtn">Submit</button>
                                                    </div> */}
                          <div className="col-md-6 mb-3">
                            <button onClick={() => setShowMap(!showMap)} className="showBtn">{showMap ? "Show Map" : "Hide Map"}</button>
                          </div>
                          <div className="col-md-6 selectBox mb-3">
                            <Form.Select aria-label="Default select example"
                              onChange={(e) => seFilterId(e.target.value)}
                            >
                              <option>Filter By</option>

                              <option value="Still Parked">Still Parked</option>
                              <option value="Already Checkedout">Already Checkedout</option>

                            </Form.Select>
                          </div>
                          <div className="col-md-12 mb-3">
                            {
                              !showMap && <div className="road-map ">

                                <MapContainer
                                  center={center}
                                  zoom={13}
                                  scrollWheelZoom={true}
                                  style={{ height: '80vh', width: '100%' }}
                                >
                                  <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                  />
                                  <MoveToLocation center={center} />
                                  {
                                    DrowData && DrowData.length > 0 ? (
                                      DrowData?.map((loc, index) => {
                                        const position = [parseFloat(loc.slot_gps_latitude), parseFloat(loc.slot_gps_longitude)];
                                        const icon = getMarkerIcon(loc.gsm_signal_strength);

                                        return (
                                          <div key={index}>
                                            {/* Render Slot Marker */}
                                            {/* <Marker position={position} icon={icon}>
                                              
                                            </Marker> */}

                                            {/* Render Geofences */}
                                            {loc.geofence_details?.map((geofence, gIndex) => {
                                              const [lat, lng] = geofence.geofence_draw_point.split(',').map(parseFloat);

                                              // Render circle
                                              if (geofence.geofence_draw_type === 'circle' && geofence.geofence_details === 'latlong') {
                                                return (
                                                  <Circle
                                                    key={`circle-${gIndex}`}
                                                    center={[lat, lng]}
                                                    radius={parseFloat(geofence.geofence_draw_point)}
                                                  
                                                    color="blue"
                                                    fillOpacity={0.2}
                                                  />
                                                );
                                              }

                                              // Render rectangle
                                              if (geofence.geofence_draw_type === 'rectangle') {
                                                const corners = loc.geofence_details
                                                  .filter(item => item.geofence_draw_type === 'rectangle')
                                                  ?.map(point => point.geofence_draw_point.split(',')?.map(parseFloat));
                                                return (
                                                  <Rectangle
                                                    key={`rectangle-${gIndex}`}
                                                    bounds={corners}
                                                    color="green"
                                                    fillOpacity={0.2}
                                                  />
                                                );
                                              }

                                              // Render polygon
                                              if (geofence.geofence_draw_type === 'polygon') {
                                                const points = loc.geofence_details
                                                  .filter(item => item.geofence_draw_type === 'polygon')
                                                  ?.map(point => point.geofence_draw_point.split(',')?.map(parseFloat));
                                                return (
                                                  <Polygon
                                                    key={`polygon-${gIndex}`}
                                                    positions={points}
                                                    color="purple"
                                                    fillOpacity={0.2}
                                                  />
                                                );
                                              }

                                              return null;
                                            })}

                                            {/* Render Vehicle Markers */}
                                            {loc.vehicles && loc.vehicles?.map((vehicle, vIndex) => {
                                              const vehiclePosition = [vehicle.latitude, vehicle.longitude];
                                              return (
                                                <Marker
                                                  key={`vehicle-${vIndex}`}
                                                  position={vehiclePosition}
                                                  icon={icon}
                                                />
                                              );
                                            })}
                                          </div>
                                        );
                                      })
                                    ) : null
                                  }

                                </MapContainer>

                              </div>
                            }
                          </div>
                          <div className="col-md-12 mb-3">
                            <div className="heighScroller">
                              <table className="table tableAdmin table-striped">
                                <thead className="tableHead">
                                  <tr>
                                    <th>{t("Sr.No")}</th>
                                    <th>{t("Slot Name")}</th>
                                    <th>{t("Vehicle Count")}</th>
                                    <th>{t("Action")}</th>
                                  </tr>
                                </thead>
                                <tbody className="tableBody">
                                  {DrowData && DrowData.length > 0 ? (
                                    DrowData?.map((itemlist, index) => {
                                      return (
                                        <>
                                          <tr>
                                            <td>{index + 1}</td>
                                            <td>{itemlist.slot_name
                                            }</td>
                                            <td>{itemlist?.vehicles ? itemlist.vehicles.length : '0'}</td>
                                            <td onClick={() => {

                                              setParkingId(itemlist?.slot_id)
                                              setShowMap(!showMap)

                                            }
                                            }><img src={step2} alt="" /></td>


                                          </tr>
                                        </>
                                      );
                                    })
                                  ) : (
                                    <><NoDataComp /></>

                                  )}
                                </tbody>

                              </table>

                            </div>
                          </div>



                          <div className="col-md-12">
                            {/* <div className="mapWrapper"> */}

                            {/* </div> */}
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="checkin">
                      <div className="parkingTabBox">
                        <div className="row">
                          <div className="col-md-3 mb-3">
                            <div className="dropDowns">
                              {/* <select
                                className="form-select"
                                aria-label="Default select example"
                                placeholder="Vehicle list"
                                onChange={(e) => setVehicleId(e.target.value)}
                              >
                                <option value={0}>Vehicle</option>
                                {vehicleList.map((vehicle) => (
                                  <option Value={vehicle.vehicle_id}>
                                    {vehicle.vehicle_number}
                                  </option>
                                ))}
                              </select> */}
                              <Select
                  style={{ height: "40px", width: "100%" }}
                  className="custom-select"
                  placeholder={t("Vehicle list")}
                  onChange={(value) => setVehicleId(value)}
                  onChange={(value) => setVehicleId(value)}
                  showSearch // Enables search functionality
                  filterOption={(input, option) =>
                    option?.label
                      ?.toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  optionFilterProp="label"
    filterSort={(optionA, optionB) =>
      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
    }
                >
                  <Option value="" style={{ color: "rgba(156, 73, 0)" }}>
                    {t("Vehicle")}
                  </Option>
                  {vehicleList && vehicleList?.length > 0 && vehicleList?.map((vehicle) => (
                    <Option
                    key={vehicle.vehicle_id}
                      value={vehicle.vehicle_id}
                      style={{ color: "rgba(156, 73, 0)" }}
                      label= {vehicle.vehicle_number}
                    >
                      {vehicle.vehicle_number}
                    </Option>
                  ))}
                </Select>
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            <div className="innerSelectBox weekCounter datepicker-main BGiMP mr-3">
                              <CommonDatePicker
                                dateKey={"toDayDate"}
                                setDate={setCurrentDate}
                                data={currentDate}
                                SetPlaceholder={"Today Date"}
                                dataDisbal={maxDate}
                              />
                            </div>
                          </div>
                          <div className="col-md-3 mb-3">
                            {/* <div className="innerSelectBox weekCounter datepicker-main BGiMP mr-3">
                                                            <CommonDatePicker
                                                                dateKey={"toDayDate"}
                                                                setDate={setCurrentDate}
                                                                data={currentDate}
                                                                SetPlaceholder={"Today Date"}
                                                                dataDisbal={maxDate}
                                                            />
                                                        </div> */}
                          </div>
                          {/* <div className="col-md-3 mb-3">
                                                        <button className="submitBtn">Submit</button>
                                                    </div> */}


                          <div className="col-md-12 selectBox mb-3">
                            <Form.Select aria-label="Default select example"
                              onChange={(e) => seFilterId(e.target.value)}
                            >
                              <option>Filter By</option>

                              <option value="Still Parked">Still Parked</option>
                              <option value="Already Checkedout">Already Checkedout</option>

                            </Form.Select>
                          </div>
                          <div className="col-md-12 mb-3">
                            <div className="heighScroller">
                              <table className="table tableAdmin table-striped">
                                <thead className="tableHead">
                                  <tr>
                                    <th>{t("Sr.No")}</th>
                                    <th>{t("Vehicle Number")}</th>
                                    <th>{t("Slot Name")}</th>
                                    <th>{t("Checkin")}</th>
                                    <th>{t("Checkout")}</th>
                                  </tr>
                                </thead>
                                <tbody className="tableBody">
                                  {OrderListData && OrderListData.length > 0 ? (
                                    OrderListData?.map((itemlist, index) => {
                                      return (
                                        <>
                                          <tr>
                                            <td>{index + 1}</td>
                                            <td>{itemlist.vehicle_number
                                            }</td>
                                            <td>{itemlist.slot_name}</td>
                                            <td>{itemlist.checkin_time}{itemlist.checkin_date}</td>

                                            <td>{itemlist.checkout_time}{itemlist.checkout_date}</td>


                                          </tr>
                                        </>
                                      );
                                    })
                                  ) : (
                                    <div className=""></div>
                                  )}
                                </tbody>

                              </table>

                            </div>
                            {OrderListData?.length === 0 && !loading && <NoDataComp />}
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  )
}

export default VehicleparkingSlot;
