import { React, useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import DDlogo from "../../assets/images/DDlogo.png";
import DatePicker from "react-datepicker";
import DatePlus from "../../assets/images/Group 33597.svg";
import redCross from "../../assets/images/redCross.svg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Select, Space, TimePicker } from "antd";
import ApiConfig from "../../api/ApiConfig";
import Form from "react-bootstrap/Form";

import { notifyError, notifySuccess } from "../../sharedComponent/notify";
import {
  postMultipartWithAuthCallWithErrorResponse,
  postMultipartWithAuthCallWithErrorResponseNodeCreate,
  postWithAuthCall,
  simpleGetCall,
  simplePostCall,
} from "../../api/ApiServices";
import moment from "moment";
import dayjs from "dayjs";
import Loader from "../../sharedComponent/Loader";
import { useTranslation } from "react-i18next";
import { FormControl } from "react-bootstrap";

const { Option } = Select;
const DispatchSetting = () => {
  const { sidebar, setSidebar, Dark, setWeekenHide,
    dispatch_settings_cod_status,
    setDispatchSettingPaymentMode } = useContext(AppContext);
    const customer_id = localStorage.getItem('customer_id')
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [timeInput, setTimeInput] = useState("");
  const [rangeConvertFlag, setRangeConvertFlag] = useState(false);
  const [dispatchSettings, setDispatchSettings] = useState({
    dispatch_periodical_trip_settings_time: [],
    deliveryCharge: 0,

  });
  

  const [value, onChange] = useState("12:00");
  const { t, i18n } = useTranslation();

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  useEffect(() => {
    getDispatchSettings();
  }, []);
  const [unit, setUnit] = useState(
    localStorage.getItem("customer_distance_unit") || "km"
  );


const timeToMinutes = (time) =>{
  const [hours, minutes, seconds] = time?.split(":")?.map(Number);

  return hours * 60 + minutes + Math.floor(seconds/60);
}


  //Conversion functions
  const kmToMiles = (km)=> km*0.621371;
  const milesToKm = (miles) => miles /0.621371;
  const getDispatchSettings = () => {
    setLoading(true);
    setRangeConvertFlag(false);
 
    simpleGetCall(ApiConfig.GET_DISPACH_SETTINGS)
      .then((res) => {
        let data = { ...res.data };
        data.dispatch_periodical_trip_settings_time =
          data.dispatch_periodical_trip_settings_time.map((item) => {
            let splitTime =
              item.dispatch_periodical_trip_settings_time.split(":");
            let hours = splitTime && splitTime[0];
            let minutes = splitTime && splitTime[1];
            let periodicTime = `${hours}:${minutes}`;
            return periodicTime;
          });
        if (res.result){
         setDispatchSettings(data);
         localStorage.setItem("dispatch_settings_default_loading_time_duration", timeToMinutes(res?.data?.dispatch_settings_default_loading_time_duration))
         localStorage.setItem("dispatch_settings_default_unloading_time_duration", timeToMinutes(res?.data?.dispatch_settings_default_unloading_time_duration))

       
        }
      })
      .catch((err) => {
        notifyError(err);
      })
      .finally(() => {
        setLoading(false);
        setRangeConvertFlag(true);
     
      });
   
  };
  
  // Preprocess dispatchSettings before sending
const preprocessDispatchSettings = (settings) => {
  const isMiles = localStorage.getItem("customer_distance_unit") === "miles";
  
                        
  return {
    ...settings,
    dispatch_setting_km_range: isMiles
      ? Math.floor(milesToKm(settings.dispatch_setting_km_range))
      : Math.floor(settings.dispatch_setting_km_range),
  };
};
  const submitData =  () => {
    // let body = JSON.stringify(dispatchSettings);
// Prepare body
// if(!dispatchSettings?.dispatch_setting_base_delivery_charges){
// set_base_delivery_charge_error("Enter base Delivery charge")
// return;
// }
const body = JSON.stringify(preprocessDispatchSettings(dispatchSettings));
    setLoading(true);
    //body.dispatch_settings_cod_status = dispatch_settings_cod_status;
    simplePostCall(ApiConfig.UPDATE_DISPATCH_SETTING, body)
      .then((res) => {
        if (
          dispatchSettings.dispatch_settings_auto_routing === "periodically" &&
          dispatchSettings?.dispatch_periodical_trip_settings_time.length === 0
        ) {
          notifyError("Please Add Periodic Trip Times");
        } else if (res.result) {
          if(customer_id== 1012 ){
            notifySuccess(res.message);
          }
          // notifySuccess(res.message);
          // getDispatchSettings();

          localStorage.setItem('dispatch_settings_cod_status', dispatchSettings.dispatch_settings_cod_status)
          setDispatchSettingPaymentMode(dispatchSettings.dispatch_settings_cod_status)
        } else {
          notifyError(res?.error)
        }
      getDispatchSettings();
      })
      .catch((err) => console.log(err));
  };

  const [errorMsg, setErrorMsg] = useState("");

  
  const [base_delivery_charge_error, set_base_delivery_charge_error] = useState("")

const [inputValue, setInputValue] = useState("");
const [inputFlag, setinputFlag] = useState(true);


useEffect(() => {
  // Set the initial input value based on user preference
  if (inputFlag) {
    if (localStorage.getItem("customer_distance_unit") === "miles") {
      setInputValue(kmToMiles(dispatchSettings?.dispatch_setting_km_range || ""));
    } else {
      setInputValue(dispatchSettings?.dispatch_setting_km_range || "");
    }
  }
}, [dispatchSettings, inputFlag]);

  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        <div id="cx-wrapper" className="Dispatch_Setting">
          {loading ? (
            <Loader />
          ) : (
            <>
              <div className="main-master-wrapper form_input_main">
                <div className="innerInputsGen" id="dispatch-setting">
                  <div className="row">
                    <div className="col-md-7">
                      <div className="innerSelectBox dispatchHead">
                        <label>{t("Dispatch Auto-routing Type")}</label>
                        <div className="multi-select-1">
                          <Select
                            style={{ width: "100%", height: "40px" }}
                            required
                            value={
                              dispatchSettings.dispatch_settings_auto_routing
                            }
                            onChange={(value) =>
                              setDispatchSettings({
                                ...dispatchSettings,
                                dispatch_settings_auto_routing: value,
                              })
                              
                            }
                          
                          >
                            <Option
                              value="instant"
                              style={{ color: "rgba(156, 73, 0)" }}
                            >
                              Instant
                            </Option>
                            <Option
                              value="periodically"
                              style={{ color: "rgba(156, 73, 0)" }}
                            >
                              Periodically
                            </Option>

                            <Option
                              value="manual"
                              style={{ color: "rgba(156, 73, 0)" }}
                            >
                              Manual
                            </Option>
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className="weekCounter col-md-12  ">
                      <label className="dispatchHead form_input_main">
                        {t("Select Dispatch Trip days")}
                      </label>
                      <div className="d-flex align-items-center flex-wrap innerToggle">
                        <div className="form-check form-switch">
                          <div className="d-flex innerFlexTog">
                            <label
                              className="form-check-label  toggleLabel"
                              for="Monday"
                            >
                              {t("Monday")}
                            </label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Monday"
                              checked={
                                dispatchSettings.dispatch_settings_monday
                              }
                              onChange={(e) =>
                                setDispatchSettings({
                                  ...dispatchSettings,
                                  dispatch_settings_monday: e.target.checked,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="form-check form-switch">
                          <div className="d-flex innerFlexTog">
                            <label
                              className="form-check-label  toggleLabel"
                              for="Tuesday"
                            >
                              {t("Tuesday")}
                            </label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Tuesday"
                              checked={
                                dispatchSettings.dispatch_settings_tuesday
                              }
                              onChange={(e) =>
                                setDispatchSettings({
                                  ...dispatchSettings,
                                  dispatch_settings_tuesday: e.target.checked,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="form-check form-switch">
                          <div className="d-flex innerFlexTog">
                            <label
                              className="form-check-label  toggleLabel"
                              for="Wednesday"
                            >
                              {t("Wednesday")}
                            </label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Wednesday"
                              checked={
                                dispatchSettings.dispatch_settings_wednesday
                              }
                              onChange={(e) =>
                                setDispatchSettings({
                                  ...dispatchSettings,
                                  dispatch_settings_wednesday: e.target.checked,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="form-check form-switch">
                          <div className="d-flex innerFlexTog">
                            <label
                              className="form-check-label  toggleLabel"
                              for="Thursday"
                            >
                              {t("Thursday")}
                            </label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Thursday"
                              checked={
                                dispatchSettings.dispatch_settings_thursday
                              }
                              onChange={(e) =>
                                setDispatchSettings({
                                  ...dispatchSettings,
                                  dispatch_settings_thursday: e.target.checked,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="form-check form-switch">
                          <div className="d-flex innerFlexTog">
                            <label
                              className="form-check-label  toggleLabel"
                              for="Friday"
                            >
                              {t("Friday")}
                            </label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Friday"
                              checked={
                                dispatchSettings.dispatch_settings_friday
                              }
                              onChange={(e) =>
                                setDispatchSettings({
                                  ...dispatchSettings,
                                  dispatch_settings_friday: e.target.checked,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="form-check form-switch">
                          <div className="d-flex innerFlexTog">
                            <label
                              className="form-check-label  toggleLabel"
                              for="Saturday"
                            >
                              {t("Saturday")}
                            </label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Saturday"
                              checked={
                                dispatchSettings.dispatch_settings_saturday
                              }
                              onChange={(e) =>
                                setDispatchSettings({
                                  ...dispatchSettings,
                                  dispatch_settings_saturday: e.target.checked,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="form-check form-switch">
                          <div className="d-flex innerFlexTog">
                            <label
                              className="form-check-label  toggleLabel"
                              for="Sunday"
                            >
                              {t("Sunday")}
                            </label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Sunday"
                              checked={
                                dispatchSettings.dispatch_settings_sunday
                              }
                              onChange={(e) =>
                                setDispatchSettings({
                                  ...dispatchSettings,
                                  dispatch_settings_sunday: e.target.checked,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="weekCounter col-md-12 "
                      style={{
                        marginTop: "15px",
                      }}
                    >
                      <div className="col-md-7">
                        <div className="innerSelectBox weekCounter">
                          <label
                            className="dispatchHead"
                            style={{ marginTop: "20px", fontSize: "17px" }}
                          ></label>
                          {errorMsg && (
                            <div style={{ color: "red" }}>{errorMsg}</div>
                          )}
                          <div className="row">
                            {/* Load ETA Input */}
                            <div className="col-md-6">
                              <Form.Label className="common-labels">
                                {t("Default Load Duration")}
                              </Form.Label>

                              <Space>
                                <TimePicker
                                  className="form-control carretClass"
                                  size="large"
                                  value={
                                    dispatchSettings.dispatch_settings_default_loading_time_duration
                                      ? dayjs(
                                        dispatchSettings.dispatch_settings_default_loading_time_duration,
                                        "HH:mm:ss"
                                      )
                                      : dayjs("00:00:00", "HH:mm:ss")
                                  }
                                  onChange={(e) => {
                                    let time = e
                                      ? e?.format("HH:mm:ss")
                                      : "00:00:00";

                                    // setDispatchloadtime(time)

                                    setDispatchSettings({
                                      ...dispatchSettings,
                                      dispatch_settings_default_loading_time_duration:
                                        time,
                                    });
                                    setErrorMsg("");
                                  }}
                                  format={"HH:mm:ss"}
                                />
                              </Space>
                            </div>

                            {/* Unload ETA Input */}
                            <div className="col-md-6">
                              <Form.Label className="common-labels">
                                {t("Default Unload Duration")}
                              </Form.Label>
                              <Space>
                                <TimePicker
                                  className="form-control carretClass"
                                  size="large"
                                  value={
                                    dispatchSettings.dispatch_settings_default_unloading_time_duration
                                      ? dayjs(
                                        dispatchSettings.dispatch_settings_default_unloading_time_duration,
                                        "HH:mm:ss"
                                      )
                                      : dayjs("00:00:00", "HH:mm:ss")
                                  }
                                  onChange={(e) => {
                                    let time = e
                                      ? e?.format("HH:mm:ss")
                                      : "00:00:00";
                                    // setDispatchUnloadtime(time);
                                    setDispatchSettings({
                                      ...dispatchSettings,
                                      dispatch_settings_default_unloading_time_duration:
                                        time,
                                    });
                                    setErrorMsg("");
                                  }}
                                  format={"HH:mm:ss"}
                                />
                              </Space>
                            </div>
                            <div className="col-md-12 mt-5">

<div className="form-check form-switch">
  <div className="d-flex innerFlexTog">
    <label
      className="form-check-label  toggleLabel"
      for="Sunday"
      style={{ fontSize: '15px' }}

    >
      {t("Navigation")}
    </label>
    <input
      className="form-check-input"
      type="checkbox"
     
      checked={dispatchSettings?.dispatch_settings_navigation}
      onChange={(e) => {

        setDispatchSettings(prev => ({
          ...prev,
          // dispatch_deliver_charge: e.target.checked ? 1 : 0
          dispatch_settings_navigation: e.target.checked ? 1 : 0
        }))

      }}
    />
  </div>
</div>

</div>
          <div className="col-md-6 mt-2 form_input_main">
                                  <Form.Label className="common-labels" /*style={{marginRight:'-2.5rem'}}*/>
                              {localStorage.getItem("customer_distance_unit")==='km' ? "Maximum Deliverable Distance (KM)" : "Maximum Deliverable Distance (Miles)"} <span>&#42;</span>
                                  </Form.Label>
                                  <Form.Control
                                    required
                                    type="number"
                                    min={0}
                                    placeholder="Enter Distance..."
                                    // value={localStorage.getItem("customer_distance_unit") === 'miles' ? 
                                    //   kmToMiles(dispatchSettings?.dispatch_setting_km_range) : dispatchSettings?.dispatch_setting_km_range
                                    // }
                                    value={inputValue ? Math.floor(inputValue) : inputValue}
                                    // onChange={(e)=>{
                                    //   setDispatchSettings((prevData)=>({
                                    //   ...prevData,
                                    //   dispatch_setting_km_range : e.target.value
                                    // }))
                                    // }}
                                    // onFocus={()=>setinputFlag(false)}
                                    onChange={(e) => {
        const value = e.target.value;
        setinputFlag(false);
        setInputValue(value); // Update the input field value directly
        setDispatchSettings((prevData) => ({
          ...prevData,
          dispatch_setting_km_range: value
           
        }));
      }}

                                  />
 
                                </div>
                                <div className="col-md-6 mt-2 form_input_main"></div>
                                <div className="col-md-6 mt-2 form_input_main">
                                  <Form.Label className="common-labels" >
                                   Minimum Order Price
                                  </Form.Label>
                                  <Form.Control
                                    required
                                    type="number"
                                    min={0}
                                    placeholder="Enter Minimum Order Price..."
                                   
                                    value={dispatchSettings?.dispatch_settings_minimum_order_price}
                                  
                                  
                                    onChange={(e) => {
        
        setDispatchSettings((prevData) => ({
          ...prevData,
          dispatch_settings_minimum_order_price: e.target.value
           
        }));
      }}

                                  />
 
                                </div>
                                <div className="col-md-6 mt-2 form_input_main">
                                  <Form.Label className="common-labels" >
                                Order Cancellation Minutes
                                  </Form.Label>
                                  <Form.Control
                                    required
                                    type="number"
                                    min={0}
                                    placeholder="Enter Order Cancellation Minutes..."
                                   
                                    value={dispatchSettings?.dispatch_settings_cancel_in_minutes}
                                  
                                  
                                    onChange={(e) => {
        
        setDispatchSettings((prevData) => ({
          ...prevData,
          dispatch_settings_cancel_in_minutes:  e.target.value
           
        }));
      }}

                                  />
 
                                </div>
                                <div className="col-md-12 mt-5">

                              <div className="form-check form-switch">
                                <div className="d-flex innerFlexTog">
                                  <label
                                    className="form-check-label  toggleLabel"
                                    for="Sunday"
                                    style={{ fontSize: '15px' }}

                                  >
                                    {t("Delivery Charge")}
                                  </label>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Sunday"
                                    checked={dispatchSettings?.dispatch_setting_delivery_charge}
                                    onChange={(e) => {

                                      setDispatchSettings(prev => ({
                                        ...prev,
                                        // dispatch_deliver_charge: e.target.checked ? 1 : 0
                                        dispatch_setting_delivery_charge: e.target.checked ? 1 : 0
                                      }))

                                    }}
                                  />
                                </div>
                              </div>

                            </div>
                            {
                              dispatchSettings?.dispatch_setting_delivery_charge == 1 &&
                              <>
                                <div className="col-md-6 form_input_main">
                                  <Form.Label className="common-labels">
                                    {t("Base Delivery Charge")} <span>&#42;</span>
                                  </Form.Label>
                                  <Form.Control
                                    required
                                    type="number"
                                    min={0}
                                    placeholder={t("Enter Base Delivery Charger...")}
                                    
                                    value={dispatchSettings?.dispatch_setting_base_delivery_charges ? dispatchSettings?.dispatch_setting_base_delivery_charges : ""}
                                    onChange={(e)=>{
                                      setDispatchSettings((prevData)=>({
                                      ...prevData,
                                      dispatch_setting_base_delivery_charges : e.target.value
                                    }))
                                    }}

                                  />
{/* <div className="">{base_delivery_charge_error}</div> */}
                                </div>

                                <div className="col-md-6 form_input_main">
                                  <Form.Label className="common-labels">
                                    {t("Base Delivery Charge / Km")} <span>&#42;</span>
                                  </Form.Label>
                                  <Form.Control
                                    required
                                    type="number"
                                    min={0}
                                    placeholder={t("Enter Base Delivery Charger/KM...")}
                                    
                                    value={dispatchSettings?.dispatch_setting_charges_km ? dispatchSettings?.dispatch_setting_charges_km : ""}
                                    onChange={(e)=>{
                                      setDispatchSettings((prevData)=>({
                                      ...prevData,
                                      dispatch_setting_charges_km : e.target.value
                                    }))
                                    }}

                                  />

                                </div>
                                {/* <div className="col-md-6">
                                  <div className="innerSelectBox dispatchHead">
                                    <label>{t("Base Delivery Charge / Km")}</label>
                                    <div className="multi-select-1">
                                      <Select
                                        style={{ width: "100%", height: "40px" }}
                                        required

                                      >
                                        <Option
                                          value="instant"
                                          style={{ color: "rgba(156, 73, 0)" }}
                                        >
                                          Instant
                                        </Option>
                                        <Option
                                          value="periodically"
                                          style={{ color: "rgba(156, 73, 0)" }}
                                        >
                                          Periodically
                                        </Option>

                                        <Option
                                          value="manual"
                                          style={{ color: "rgba(156, 73, 0)" }}
                                        >
                                          Manual
                                        </Option>
                                      </Select>
                                    </div>
                                  </div>
                                </div> */}

                                <div className="col-md-6 form_input_main">
                                  <Form.Label className="common-labels">
                                    {t(" Per Extra km / Charge")} <span>&#42;</span>
                                  </Form.Label>
                                  <Form.Control
                                    required
                                    type="number"
                                    min={0}
                                    placeholder={t("Enter Per Extra Km Charge...")}
                                    value={dispatchSettings?.dispatch_setting_per_extra_km_charge ? dispatchSettings?.dispatch_setting_per_extra_km_charge : ""}
                                    onChange={(e)=>{
                                      setDispatchSettings((prevData)=>({
                                      ...prevData,
                                      dispatch_setting_per_extra_km_charge : e.target.value
                                    }))
                                    }}

                                  />

                                </div>


                                <div className="col-md-6 form_input_main">
                                  <Form.Label className="common-labels">
                                    {t("Exemption Order Amount")} <span>&#42;</span>
                                  </Form.Label>
                                  <Form.Control
                                    required
                                    type="number"
                                    min={0}
                                    placeholder={t("Exemption Order Amount...")}
                                    value={dispatchSettings?.dispatch_setting_exemption_order_amount ? dispatchSettings?.dispatch_setting_exemption_order_amount : ""}
                                    onChange={(e)=>{
                                      setDispatchSettings((prevData)=>({
                                      ...prevData,
                                      dispatch_setting_exemption_order_amount : e.target.value
                                    }))
                                    }}

                                  />

                                </div>


                      
                            
                                <div className="col-md-6 ">

                                  <div className="form-check form-switch">
                                    <div className="d-flex innerFlexTog">
                                      <label
                                        className="form-check-label  toggleLabel"
                                        for="Sunday"
                                      >
                                        {t("Exemption Charge")}
                                      </label>
                                      <input
                                    className="form-check-input"
                                    type="checkbox"
                                   
                                    checked={dispatchSettings?.dispatch_setting_charge_exemption}
                                    onChange={(e) => {

                                      setDispatchSettings(prev => ({
                                        ...prev,
                                        // dispatch_deliver_charge: e.target.checked ? 1 : 0
                                        dispatch_setting_charge_exemption: e.target.checked ? 1 : 0
                                      }))

                                    }}
                                  />
                                    </div>
                                  </div>

                                </div>

                              </>
                            }
                            <div className="col-md-12 mt-4">
                              <div className="form-check form-switch">
                                <p className="dispatchHead form_input_main">Payment Mode</p>
                                <div className="d-flex gap-2">
                                  <div className="d-flex innerFlexTog">
                                    <label
                                      className="form-check-label  toggleLabel"
                                      for="Sunday"
                                      style={{ fontSize: "16px" }}
                                    >
                                      {t(
                                        "Cash On Delivery ( COD )"
                                      )}
                                    </label>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="Sunday"
                                      checked={
                                        dispatchSettings.dispatch_settings_cod_status
                                      }
                                      onChange={(e) =>
                                        setDispatchSettings({
                                          ...dispatchSettings,
                                          dispatch_settings_cod_status:
                                            e.target.checked ? 1 : 0,
                                        })
                                      }
                                    />
                                  </div>
                                  <div className="d-flex innerFlexTog">
                                    <label
                                      className="form-check-label  toggleLabel"
                                      for="Sunday"
                                      style={{ fontSize: "16px" }}
                                    >
                                      {t(
                                        "Online"
                                      )}
                                    </label>
                                    <input
                                    className="form-check-input"
                                    type="checkbox"
                                   
                                    checked={dispatchSettings?.settings_online_payment}
                                    onChange={(e) => {

                                      setDispatchSettings(prev => ({
                                        ...prev,
                                        // dispatch_deliver_charge: e.target.checked ? 1 : 0
                                        settings_online_payment: e.target.checked ? 1 : 0
                                      }))

                                    }}
                                  />
                                  </div>
                                </div>
                              </div>
                              <div className="form-check form-switch mt-2">
                                <div className="d-flex innerFlexTog">
                                  <label
                                    className="form-check-label  toggleLabel"
                                    for="Sunday"
                                    style={{ fontSize: "16px" }}
                                  >
                                    {t(
                                      "Dispatch Auto update Based On Geofence Location" 
                                    )}
                                  </label>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Sunday"
                                    checked={
                                      dispatchSettings.dispatch_settings_status_auto_update
                                    }
                                    onChange={(e) =>
                                      setDispatchSettings({
                                        ...dispatchSettings,
                                        dispatch_settings_status_auto_update:
                                          e.target.checked,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {dispatchSettings.dispatch_settings_auto_routing ===
                      "periodically" && (
                        <>
                          <div className="col-md-7">
                            <div className="innerSelectBox weekCounter">
                              <label className="dispatchHead">
                                {t("Periodic Trip Times")} <span>&#42;</span>
                              </label>
                              <Space>
                                <TimePicker
                                  className="form-control carretClass"
                                  size="large"
                                  value={
                                    timeInput
                                      ? dayjs(timeInput, "HH:mm")
                                      : dayjs("00:00", "HH:mm")
                                  }
                                  onChange={(e) => {
                                    let time = e
                                      ? e?.hour() + ":" + e?.minute()
                                      : "00:00";
                                    setTimeInput(time);

                                    setErrorMsg("");
                                  }}
                                  format={"HH:mm"}
                                />
                              </Space>
                              {errorMsg && (
                                <div style={{ color: "red" }}>{errorMsg}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-2 weekCounter">
                            <button
                              className="AddOnBtn"
                              onClick={() => {
                                if (timeInput === "") {
                                  setErrorMsg(
                                    "Please enter a periodic trip time"
                                  );
                                } else {
                                  let timeArray = [
                                    ...dispatchSettings.dispatch_periodical_trip_settings_time,
                                  ];
                                  if (timeInput)
                                    setDispatchSettings({
                                      ...dispatchSettings,
                                      dispatch_periodical_trip_settings_time: [
                                        ...timeArray,
                                        timeInput,
                                      ],
                                    });
                                  setTimeInput("");
                                }
                              }}
                            >
                              {t("Add")}
                            </button>
                          </div>
                        </>
                      )}
                  </div>
                  {dispatchSettings.dispatch_settings_auto_routing ===
                    "periodically" &&
                    Array.isArray(
                      dispatchSettings.dispatch_periodical_trip_settings_time
                    ) &&
                    dispatchSettings.dispatch_periodical_trip_settings_time.map(
                      (time, index) => {
                        let splittedTimeArr = time && time.split(":");
                        let hours =
                          Number(splittedTimeArr[0]) < 10
                            ? "0" + Number(splittedTimeArr[0])
                            : splittedTimeArr[0];
                        let minutes =
                          Number(splittedTimeArr[1]) < 10
                            ? "0" + Number(splittedTimeArr[1])
                            : splittedTimeArr[1];
                        return (
                          <div style={{ display: "flex" }}>
                            <div
                              className="selectedDate"
                              key={"selectedDate" + index}
                            >
                              <p className="innerDate">{`${hours}:${minutes}`}</p>
                            </div>
                            <Link to="#">
                              <img
                                src={redCross}
                                alt=""
                                onClick={() =>
                                  setDispatchSettings({
                                    ...dispatchSettings,
                                    dispatch_periodical_trip_settings_time:
                                      dispatchSettings.dispatch_periodical_trip_settings_time.filter(
                                        (item) => item != time
                                      ),
                                  })
                                }
                              />
                            </Link>
                          </div>
                        );
                      }
                    )}
                </div>
              </div>
              {/* Two Bottom Buttons */}
              <div className="d-flex justify-content-end align-items-center btn-wrapper">
                {/* <button type="button" className="cx-btn-1">{t("Cancel")}</button> */}
                <button className="cx-btn-2" onClick={submitData}>
                  {t("Submit")}
                </button>
              </div>
            </>
          )}
        </div>
      </motion.div>
    </>
  );
};

export default DispatchSetting;
